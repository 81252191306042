import AppsRequests from '@/utils/requestClasses/apps';
import ACLRequests from '@/utils/requestClasses/acl';
import AuthConfigRequests from '@/utils/requestClasses/authConfig';
import ComponentRequests from '@/utils/requestClasses/component';
import ConfigRequests from '@/utils/requestClasses/config';
import IndexRequests from '@/utils/requestClasses/dataIndex';
import DocsRequests from '@/utils/requestClasses/docs';
import EngineVersionRequests from '@/utils/requestClasses/engineVersion';
import FlowRequests from '@/utils/requestClasses/flow';
import InsightsRequests from '@/utils/requestClasses/insight';
import PublicFilesRequests from '@/utils/requestClasses/publicFiles';
import QuotaRequests from '@/utils/requestClasses/quota';
import StatusRequests from '@/utils/requestClasses/status';
import UserRequests from '@/utils/requestClasses/user';
import AbstractProvider from '@/services/ModulesProvider/AbstractProvider';
import AWSProvider from '@/services/ModulesProvider/AWSProvider';

export default function register(DependencyContainer) {
    DependencyContainer.registerSingleton(ACLRequests, async () => {
        return new ACLRequests();
    });

    DependencyContainer.registerSingleton(AppsRequests, async () => {
        return new AppsRequests();
    });

    DependencyContainer.registerSingleton(AuthConfigRequests, async () => {
        return new AuthConfigRequests();
    });

    DependencyContainer.registerSingleton(ComponentRequests, async () => {
        return new ComponentRequests();
    });

    DependencyContainer.registerSingleton(ConfigRequests, async () => {
        return new ConfigRequests();
    });

    DependencyContainer.registerSingleton(IndexRequests, async () => {
        return new IndexRequests();
    });

    DependencyContainer.registerSingleton(DocsRequests, async () => {
        return new DocsRequests();
    });

    DependencyContainer.registerSingleton(EngineVersionRequests, async () => {
        return new EngineVersionRequests();
    });

    DependencyContainer.registerSingleton(FlowRequests, async () => {
        return new FlowRequests();
    });

    DependencyContainer.registerSingleton(InsightsRequests, async () => {
        return new InsightsRequests();
    });

    DependencyContainer.registerSingleton(PublicFilesRequests, async () => {
        return new PublicFilesRequests();
    });

    DependencyContainer.registerSingleton(QuotaRequests, async () => {
        return new QuotaRequests();
    });

    DependencyContainer.registerSingleton(StatusRequests, async () => {
        return new StatusRequests();
    });

    DependencyContainer.registerSingleton(UserRequests, async () => {
        return new UserRequests();
    });

    DependencyContainer.registerSingleton(AbstractProvider, async () => {
        return new AWSProvider();
    });
}
