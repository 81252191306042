import appmixer from '../../plugins/appmixer';
import QueryUtils from '../query';

export default {
    authenticateUser(username, password) {
        return appmixer.api.authenticateUser(username, password);
    },

    queryAuthUser() {
        return appmixer.api.getUser();
    },

    queryUser(userId) {
        return appmixer.api.request({
            url: `${appmixer.api.baseUrl}/users/${userId}`,
            method: 'GET'
        });
    },

    queryUsers(query = {}) {
        query = QueryUtils.processQuery(query);
        return appmixer.api.request({
            url: `${appmixer.api.baseUrl}/users`,
            method: 'GET',
            params: query
        });
    },

    queryUsersCount(query = {}) {
        query = QueryUtils.processQuery(query);
        return appmixer.api.request({
            url: `${appmixer.api.baseUrl}/users/count`,
            method: 'GET',
            params: query
        });
    },

    updateUser(userId, payload) {
        return appmixer.api.request({
            url: `${appmixer.api.baseUrl}/users/${userId}`,
            method: 'PUT',
            data: payload
        });
    },

    removeUser(userId) {
        const { api } = appmixer;
        const { baseUrl } = api;

        return api.request({
            url: `${baseUrl}/users/${userId}`,
            method: 'DELETE'
        });
    },

    removeStatus(userId, ticket) {
        const { api } = appmixer;
        const { baseUrl } = api;

        return api.request({
            url: `${baseUrl}/users/${userId}/delete-status/${ticket}`,
            method: 'GET'
        });
    }
};
