// https://vuex.vuejs.org/en/modules.html
import app from './app';
import engine from './engine';
import modules from './modules';
import permissions from './permissions';
import user from './user';

const storeModules = {
    app: {
        ...app,
        namespaced: true
    },
    engine: {
        ...engine,
        namespaced: true
    },
    modules: {
        ...modules,
        namespaced: true
    },
    permissions: {
        ...permissions,
        namespaced: true
    },
    user: {
        ...user,
        namespaced: true
    }
};

export default storeModules;
