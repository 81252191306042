import DependencyContainer from './definition';
import register from '@/dependencyContainer/registrations';

let dependencyContainer;

const initDependencyContainer = () => {
    dependencyContainer = new DependencyContainer();
    register(dependencyContainer);

    return dependencyContainer;
};

const getDependencyContainer = () => {
    return dependencyContainer;
};

export {
    getDependencyContainer,
    initDependencyContainer
};
