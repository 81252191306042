import appmixer from '../../plugins/appmixer';
import FileUtils from '../file';

export default class AppsRequests {
    async queryApps() {
        return appmixer.api.request({
            url: `${appmixer.api.baseUrl}/apps`,
            method: 'GET',
            params: {
                ignoreACL: true
            }
        });
    }

    queryBundles() {
        return appmixer.api.request({
            url: `${appmixer.api.baseUrl}/bundles`,
            method: 'GET'
        });
    }

    queryComponents() {
        return appmixer.api.request({
            url: `${appmixer.api.baseUrl}/components`,
            method: 'GET',
            params: {
                manifest: 'yes',
                private: 'no',
                ignoreACL: true
            }
        });
    }

    async upload(file) {
        const fileBuffer = await FileUtils.fileToBuffer(file);
        return appmixer.api.request({
            url: `${appmixer.api.baseUrl}/components`,
            method: 'POST',
            params: {
                replaceAll: true
            },
            data: fileBuffer,
            headers: {
                'Content-Type': 'application/octet-stream'
            }
        });
    }

    uploadStatus(ticket) {
        return appmixer.api.request({
            url: `${appmixer.api.baseUrl}/components/uploader/${ticket}`,
            method: 'GET'
        });
    }

    delete(name) {
        return appmixer.api.request({
            url: `${appmixer.api.baseUrl}/components/${name}`,
            method: 'DELETE'
        });
    }
};
