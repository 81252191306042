import appmixer from '../../plugins/appmixer';

export default class ComponentRequests {
    async download(name) {
        return appmixer.api.request({
            url: `${appmixer.api.baseUrl}/components/${name}`,
            method: 'GET',
            responseType: 'arraybuffer'
        });
    }
};
