import AppsRequests from '@/utils/requestClasses/apps';
import _ from 'lodash';
import { getDependencyContainer } from '@/dependencyContainer';
import AbstractProvider from '@/services/ModulesProvider/AbstractProvider';

async function loadAsyncData({ commit }, { dataCallback, mutationName, withLoaders = true }) {
    if (withLoaders) {
        commit(mutationName, { loading: true });
    }
    const data = await dataCallback();
    commit(mutationName, { data });
    if (withLoaders) {
        commit(mutationName, { loading: false });
    }
}

export default {
    loadModuleData({ dispatch }) {
        return Promise.all([
            dispatch('loadAvailableModules'),
            dispatch('loadInstalledModules')
        ]);
    },

    loadComponentData({ dispatch }, { module, version }) {
        return Promise.all([
            dispatch('loadAvailableComponents', { module, version }),
            dispatch('loadInstalledComponents')
        ]);
    },

    async loadAvailableModules({ commit }, { withLoaders = true } = {}) {
        if (withLoaders) {
            commit('setAvailableModulesState', { loading: true });
        }
        const dependencyContainer = getDependencyContainer();
        const provider = await dependencyContainer.get(AbstractProvider);
        const data = await provider.getModules();

        const modulesData = data.map(item => _.omit(item, 'components'));
        commit('setAvailableModulesState', { data: modulesData });

        if (withLoaders) {
            commit('setAvailableModulesState', { loading: false });
        }
    },

    async loadInstalledModules({ commit }, { withLoaders } = {}) {
        async function cb() {
            const dependencyContainer = getDependencyContainer();
            const appsReq = await dependencyContainer.get(AppsRequests);

            const bundles = await appsReq.queryBundles();
            const apps = await appsReq.queryApps();

            // Merge bundle information is available
            return Object.entries(apps).reduce((acc, [key, value]) => {
                if (bundles[key]) {
                    acc.push({ ...value, bundleVersion: bundles[key].version });
                } else {
                    acc.push({ ...value, bundleVersion: value.version || '1.0.0' });
                }
                return acc;
            }, []);
        }

        return loadAsyncData({ commit }, { dataCallback: cb, mutationName: 'setInstalledModulesState', withLoaders });
    },

    async loadAvailableComponents({ state, commit }, { module, version, withLoaders = true } = {}) {
        async function cb() {
            const dependencyContainer = getDependencyContainer();
            const provider = await dependencyContainer.get(AbstractProvider);
            const components = await provider.getComponents(module, version);
            return _.uniqBy([...state.components.available.data, ...components], 'name');
        }

        return loadAsyncData({ commit }, {
            dataCallback: cb,
            mutationName: 'setAvailableComponentsState',
            withLoaders
        });
    },

    async loadInstalledComponents({ commit }, { module, withLoaders = true } = {}) {
        async function cb() {
            const dependencyContainer = getDependencyContainer();
            const components = await (await dependencyContainer.get(AppsRequests)).queryComponents();
            return Object.values(components);
        }

        return loadAsyncData({ commit }, {
            dataCallback: cb,
            mutationName: 'setInstalledComponentsState',
            withLoaders
        });
    }
};
