import QueryUtils from '../query';
import appmixer from '../../plugins/appmixer';

export default class FlowRequests {
    get DEFAULT_PAGE_SIZE() { return 10; }

    queryFlows(query = {}) {
        query = QueryUtils.processQuery(query);
        return appmixer.api.getFlows(query);
    }

    queryFlowsCount(query = {}) {
        query = QueryUtils.processQuery(query);
        return appmixer.api.getFlowsCount(query);
    }

    getTelemetry(flowId) {
        return appmixer.api.request({
            url: `${appmixer.api.baseUrl}/telemetry/flows/${flowId}`,
            method: 'GET'
        });
    }

    stopFlowsByModule(module) {
        return appmixer.api.request({
            url: `${appmixer.api.baseUrl}/system/stop-flows/${module}`,
            method: 'POST'
        });
    }

    stopFlowsStatus(ticket) {
        return appmixer.api.request({
            url: `${appmixer.api.baseUrl}/system/stop-flows/status/${ticket}`,
            method: 'GET'
        });
    }

    cancelStopFlows(ticket) {
        return appmixer.api.request({
            url: `${appmixer.api.baseUrl}/system/stop-flows/cancel/${ticket}`,
            method: 'POST'
        });
    }
};
