export default class DependencyContainer {
    constructor() {
        this.registered = {};
        this.singletons = {};
    }

    register(className, factoryFn) {
        const name = typeof className === 'string' ? className : className.name;
        this.registered[name] = { factoryFn, isSingleton: false };
    }

    registerSingleton(className, factoryFn) {
        const name = typeof className === 'string' ? className : className.name;
        this.registered[name] = { factoryFn, isSingleton: true };
    }

    get(className) {
        const name = typeof className === 'string' ? className : className.name;
        const register = this.registered[name];

        if (!register) {
            throw new Error(`Dependency ${className} not registered`);
        }

        if (register.isSingleton && this.singletons[name]) {
            return this.singletons[name];
        }
        const dependency = register.factoryFn();
        if (register.isSingleton) {
            this.singletons[name] = dependency;
            return this.singletons[name];
        }
        return dependency;
    }
}
